import Filter from "./Filter";

const AllCategories= () => {
    return ( 
 
        <div className='column'>
        <Filter  />
        </div>

    )
}
export default AllCategories;