const Welcome =()=>{

   
    return (
        <div className="welcomePage App">
<p className="teteWhite">Mélanges de thé & tisane </p>
<p className='welcomePar ' >pour vous guider d’une longue journée, directement dans le pays des rêves...</p>
<br/> <br/>
<a className='btnSendTwo' href= "#down" >
        voir les produits
      </a>
        </div>
    )
}

export default Welcome;