import logotea from './logotea.png';
import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";





import Cart from './Components/Cart/Cart';
import Home from './Components/ThesComponents/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import TotalArticlesNav from './Components/Cart/TotalArticlesNav';
import { useState } from 'react';
import MailContactForm from './Components/MailContactForm';

// import CartModal from './Components/Cart/CartModal';

function App() {

  window.onscroll = function() {
    const hiddenNav = document.querySelector("#hiddenNav");
    const sticky = hiddenNav.offsetTop;

    if (window.pageYOffset > sticky + 100) {
      hiddenNav.classList.add("sticky");
      hiddenNav.classList.remove("hiddenNav")
      } else {
        hiddenNav.classList.remove("sticky");
        hiddenNav.classList.add("hiddenNav")      }
};


const [isActive, setActive] = useState(true);

const toggleCart = () => {
  setActive(!isActive);
};


const myFunctionMobilePlus =()=>{
  let links = document.querySelector("#hiddenLinks");
    if (links.style.display === "block" && window.innerWidth <= 1370) {
        links.style.display = "none";
    }
    else {
        links.style.display = "block";

        
    }

}

  return (
    <Router >
      <div className='App'>
        <p className='shipping greenback'>ENVOI GRATUIT à partir de 70 €</p>
      </div>

      <div className={isActive ? 'panierBlock close': 'panierBlock open'} >
      <Cart/>
      </div>
      <div className='App head'>
        <div className='cont '>
          <Link to="/">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 211 66" className='tealealogo'>
  <path id="Chemin importe"
        fill="currentColor" stroke="none" strokeWidth="1"
        d="M 20.99,9.30 C 20.99,29.21 20.99,25.62 
        20.99,58.00 20.99,59.03 21.18,59.79 
        21.57,60.25 21.95,60.71 22.58,60.94 
        23.46,60.94 24.06,60.94 24.77,60.77 
        25.59,60.41 26.41,60.06 27.17,59.45 
        27.89,58.58 27.89,58.58 29.12,59.55 29.12,59.55
        27.97,61.51 26.45,63.07 24.56,64.24
        22.67,65.41 20.31,65.99 17.48,65.99
        15.67,65.99 14.04,65.76 12.60,65.30
        11.15,64.84 9.96,64.15 9.03,63.22
        7.83,62.02 7.06,60.54 6.73,58.77
        6.40,57.01 6.23,54.71 6.23,51.88
             6.23,51.88 6.23,24.14 6.23,24.14
             6.23,24.14 0.00,24.14 0.00,24.14
             0.00,24.14 0.00,22.51 0.00,22.51
             0.00,22.51 6.23,22.51 6.23,22.51
             6.23,22.51 6.23,12.88 6.23,12.88
             9.13,12.88 11.77,12.60 14.15,12.03
             16.53,11.45 18.81,10.54 20.99,9.30
             20.99,9.30 20.99,9.30 20.99,9.30 Z
           M 22.26,24.89
           C 22.26,24.89 22.26,24.85 22.26,24.85
             22.26,24.85 22.33,24.94 22.33,24.94
             22.33,24.94 22.26,24.89 22.26,24.89 Z
           M 22.07,23.12
           C 21.97,13.37 39.07,10.21 45.72,16.94
             37.68,22.12 30.53,30.80 22.78,25.27
             26.22,22.14 30.54,19.35 36.94,17.43
             31.35,17.96 26.40,20.45 22.07,23.12
             22.07,23.12 22.07,23.12 22.07,23.12 Z
           M 52.16,21.37
           C 56.96,21.37 60.75,22.78 63.51,25.58
             66.27,28.37 67.65,33.07 67.65,39.65
             67.65,39.65 41.99,39.65 41.99,39.65
             41.99,39.65 41.83,38.09 41.83,38.09
             41.83,38.09 55.68,38.09 55.68,38.09
             55.73,35.43 55.63,32.97 55.35,30.71
             55.08,28.46 54.64,26.65 54.04,25.29
             53.44,23.93 52.62,23.25 51.58,23.25
             50.10,23.25 48.90,24.41 47.98,26.72
             47.04,29.03 46.44,32.99 46.17,38.59
             46.17,38.59 46.50,39.24 46.50,39.24
             46.45,39.78 46.43,40.33 46.43,40.87
             46.43,41.41 46.43,41.98 46.43,42.58
             46.43,46.39 46.91,49.36 47.86,51.47
             48.82,53.59 50.02,55.06 51.47,55.87
             52.91,56.69 54.38,57.09 55.86,57.09
             56.94,57.09 58.42,56.81 60.28,56.24
             62.13,55.66 63.97,54.13 65.77,51.63
             65.77,51.63 67.17,52.12 67.17,52.12
             66.51,54.30 65.46,56.45 64.01,58.61
             62.57,60.76 60.70,62.53 58.40,63.91
             56.10,65.30 53.31,65.99 50.04,65.99
             46.54,65.99 43.36,65.28 40.49,63.87
             37.61,62.46 35.33,60.12 33.64,56.85
             31.95,53.58 31.10,49.21 31.10,43.72
             31.10,38.49 32.03,34.24 33.89,30.95
             35.74,27.65 38.28,25.24 41.47,23.69
             44.67,22.14 48.24,21.36 52.17,21.36
             52.17,21.36 52.16,21.37 52.16,21.37 Z
           M 82.18,65.42
           C 78.46,65.42 75.77,64.42 74.10,62.44
             72.43,60.46 71.60,58.16 71.60,55.54
             71.60,53.20 72.12,51.30 73.16,49.83
             74.19,48.37 75.55,47.17 77.21,46.25
             78.88,45.32 80.65,44.55 82.50,43.92
             84.35,43.30 86.12,42.69 87.79,42.09
             89.45,41.49 90.81,40.80 91.84,40.01
             92.88,39.22 93.40,38.20 93.40,36.95
             93.40,36.95 93.40,29.69 93.40,29.69
             93.40,28.82 93.25,27.87 92.95,26.83
             92.64,25.80 92.09,24.90 91.27,24.14
             90.45,23.37 89.22,22.99 87.58,22.99
             86.71,22.99 85.87,23.11 85.08,23.36
             84.29,23.60 83.59,23.94 82.99,24.38
             84.90,25.09 86.30,26.08 87.17,27.36
             88.05,28.64 88.48,30.04 88.48,31.56
             88.48,33.74 87.72,35.45 86.18,36.70
             84.65,37.95 82.85,38.58 80.77,38.58
             78.58,38.58 76.88,37.90 75.68,36.54
             74.48,35.18 73.88,33.49 73.88,31.48
             73.88,29.79 74.31,28.37 75.15,27.20
             76.00,26.03 77.27,24.96 78.97,23.98
             80.60,23.05 82.57,22.39 84.87,21.98
             87.17,21.57 89.57,21.36 92.09,21.36
             94.77,21.36 97.25,21.62 99.55,22.14
             101.85,22.66 103.82,23.76 105.45,25.45
             106.60,26.64 107.34,28.14 107.66,29.93
             107.99,31.73 108.15,34.04 108.15,36.86
             108.15,36.86 108.15,58.73 108.15,58.73
             108.15,60.09 108.25,61.02 108.44,61.50
             108.63,61.99 108.97,62.24 109.47,62.24
             109.90,62.24 110.33,62.10 110.74,61.83
             111.15,61.56 111.55,61.29 111.93,61.02
             111.93,61.02 112.75,62.40 112.75,62.40
             111.55,63.44 110.06,64.20 108.28,64.69
             106.51,65.19 104.63,65.43 102.67,65.43
             99.11,65.43 96.74,64.80 95.57,63.55
             94.39,62.30 93.75,60.85 93.65,59.22
             92.56,61.02 91.08,62.50 89.22,63.67
             87.37,64.84 85.02,65.42 82.17,65.42
             82.17,65.42 82.18,65.42 82.18,65.42 Z
           M 90.37,58.90
           C 90.97,58.90 91.50,58.80 91.97,58.61
             92.43,58.42 92.91,58.06 93.41,57.51
             93.41,57.51 93.41,40.29 93.41,40.29
             92.86,41.27 92.18,42.13 91.36,42.87
             90.55,43.60 89.74,44.39 88.94,45.23
             88.15,46.07 87.50,47.11 86.98,48.33
             86.45,49.56 86.20,51.12 86.20,53.02
             86.20,55.20 86.59,56.72 87.38,57.59
             88.18,58.45 89.18,58.90 90.38,58.90
             90.38,58.90 90.37,58.90 90.37,58.90 Z
           M 124.18,57.92
           C 123.80,59.22 123.80,60.12 124.18,60.61
             124.56,61.10 125.00,61.35 125.50,61.35
             125.77,61.35 126.31,61.09 127.13,60.58
             127.95,60.06 128.80,58.60 129.68,56.21
             129.68,56.21 131.40,51.64 131.40,51.64
             131.40,51.64 132.96,51.64 132.96,51.64
             132.96,51.64 130.91,57.60 130.91,57.60
             130.09,59.94 129.01,61.71 127.67,62.91
             126.33,64.10 124.93,64.92 123.45,65.35
             121.98,65.78 120.59,66.00 119.27,66.00
             116.97,66.00 115.07,65.47 113.57,64.41
             112.06,63.35 111.12,61.78 110.74,59.72
             110.35,57.65 110.62,55.07 111.56,51.96
             111.56,51.96 124.10,10.44 124.10,10.44
             124.65,8.59 124.59,7.23 123.94,6.36
             123.28,5.49 121.61,5.05 118.94,5.05
             118.94,5.05 119.51,3.34 119.51,3.34
             124.00,3.28 127.98,2.97 131.44,2.40
             134.92,1.83 138.26,1.02 141.49,-0.01
             141.49,-0.01 124.19,57.92 124.19,57.92
             124.19,57.92 124.18,57.92 124.18,57.92 Z
           M 144.08,47.72
           C 145.83,46.91 147.55,45.93 149.24,44.78
             150.94,43.64 152.50,42.39 153.92,41.03
             156.10,38.91 157.90,36.49 159.29,33.77
             160.68,31.05 161.38,28.11 161.38,24.96
             161.38,24.20 161.26,23.67 161.05,23.37
             160.83,23.06 160.50,22.92 160.06,22.92
             158.70,22.92 157.19,23.84 155.55,25.69
             153.92,27.54 152.36,29.93 150.88,32.87
             149.40,35.81 148.20,38.99 147.27,42.42
             146.34,45.85 145.88,49.11 145.88,52.21
             145.88,55.36 146.51,57.47 147.77,58.53
             149.02,59.59 150.67,60.13 152.69,60.13
             153.79,60.13 155.42,59.72 157.61,58.90
             159.79,58.09 161.98,56.59 164.16,54.42
             164.16,54.42 165.48,55.07 165.48,55.07
             164.28,56.86 162.69,58.60 160.73,60.29
             158.76,61.98 156.43,63.35 153.76,64.41
             151.08,65.47 148.07,66.01 144.74,66.01
             140.91,66.01 137.59,65.05 134.78,63.11
             131.97,61.18 130.55,58.32 130.55,54.51
             130.55,51.31 131.06,48.12 132.07,44.96
             133.08,41.81 134.52,38.82 136.37,35.99
             138.23,33.16 140.45,30.64 143.05,28.44
             145.64,26.24 148.53,24.51 151.70,23.26
             154.87,22.01 158.25,21.38 161.87,21.38
             164.77,21.38 167.19,21.96 169.12,23.14
             171.06,24.31 172.03,26.12 172.03,28.56
             172.03,30.63 171.48,32.58 170.39,34.39
             169.29,36.21 167.80,37.88 165.88,39.38
             163.96,40.88 161.80,42.22 159.40,43.41
             157.00,44.61 154.46,45.64 151.82,46.52
             149.16,47.39 146.56,48.07 143.99,48.55
             143.99,48.55 144.07,47.74 144.07,47.74
             144.07,47.74 144.08,47.72 144.08,47.72 Z
           M 181.03,60.46
           C 181.58,60.46 182.15,60.25 182.75,59.81
             183.35,59.38 183.98,58.64 184.64,57.57
             185.30,56.51 186.01,55.02 186.78,53.13
             187.54,51.22 188.36,48.78 189.24,45.78
             189.24,45.78 187.11,54.67 187.11,54.67
             185.74,59.02 183.92,62.00 181.66,63.60
             179.39,65.20 176.81,66.01 173.91,66.01
             170.47,66.01 167.91,65.12 166.25,63.36
             164.58,61.59 163.75,59.08 163.75,55.81
             163.75,52.66 164.28,49.45 165.35,46.19
             166.42,42.92 167.86,39.81 169.69,36.85
             171.52,33.88 173.58,31.24 175.84,28.94
             178.10,26.62 180.47,24.79 182.93,23.43
             185.39,22.07 187.77,21.39 190.06,21.39
             191.81,21.39 193.16,21.94 194.12,23.02
             195.07,24.11 195.41,26.03 195.14,28.82
             195.14,28.82 194.24,29.31 194.24,29.31
             194.51,27.95 194.51,26.95 194.24,26.33
             193.97,25.71 193.50,25.39 192.85,25.39
             191.70,25.39 190.35,26.29 188.79,28.09
             187.23,29.88 185.72,32.26 184.28,35.23
             182.83,38.19 181.61,41.50 180.63,45.14
             179.65,48.79 179.15,52.45 179.15,56.15 
             179.15,57.67 179.29,58.77 179.56,59.45 
             179.83,60.13 180.32,60.47 181.04,60.47 
             181.04,60.47 181.03,60.46 181.03,60.46 Z M 
             196.21,22.62 C 199.21,22.57 201.87,22.45 
             204.20,22.26 206.52,22.07 208.81,21.78 
             211.04,21.40 211.04,21.40 199.98,57.95 
             199.98,57.95 199.71,58.82 199.58,59.51 
             199.61,60.03 199.64,60.55 199.78,60.90 
             200.02,61.09 200.26,61.28 200.58,61.37 
             200.96,61.37 201.46,61.37 202.08,61.09 
             202.85,60.52 203.62,59.94 204.43,58.51 
             205.31,56.24 205.31,56.24 207.03,51.67 
             207.03,51.67 207.03,51.67 208.59,51.67 
             208.59,51.67 208.59,51.67 206.54,57.63 
             206.54,57.63 205.34,61.05 203.63,63.31 
             201.41,64.40 199.20,65.49 197.00,66.03 
             194.81,66.03 191.48,66.03 189.10,65.07 
             187.68,63.17 186.86,62.02 186.41,60.53 
             186.33,58.68 186.25,56.83 186.59,54.60 
             187.36,51.99 187.36,51.99 196.22,22.62 
             196.22,22.62 196.22,22.62 196.21,22.62 196.21,22.62 Z" />
      </svg>
      </Link>
        </div>
      <nav className='myNav'>
        <Link to="/" className='link'> BOUTIQUE </Link>
        <Link to="/about" className='link'> A PROPOS </Link>
        <Link to="/contact" className='link'> CONTACT </Link>

        <span className='link'> | </span>
{/*
        <Link to="/" className='link btnMore'> 
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"  viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
          </svg>
          </Link>

  */ }
          <Link to="/" className='link btnMore'> 

          <div className='cont'  >
            <button onClick={toggleCart} className='btnMore cont' > 
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"  viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5Zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0ZM14 14V5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
          </svg>

          <span className='spanQuantityCart'> <TotalArticlesNav /> </span> 
               </button>
              
             
              
          
          
          </div>
          
          
          </Link>

      </nav>
      
    
      </div>

     

      <div className='hiddenNav' id='hiddenNav'>

      <nav className='secondeNav'  >

        <div className='navLeft'>
        <Link to="/" className='linkSmall'> 
        <img src={ logotea } className="smalLogo" alt='logo' />
        
         </Link>

<div id='hiddenLinks'> 
         <div className='columnLinks'> 
        <Link to="/" className='linkSmall' onClick={myFunctionMobilePlus}> BOUTIQUE </Link>
        <Link to="/about" className='linkSmall' onClick={myFunctionMobilePlus}> A PROPOS </Link>
        <Link to="/contact" className='linkSmall' onClick={myFunctionMobilePlus}> CONTACT </Link>
        </div>
</div>
        <div className ="burger">
            <button className="iconBurger"  onClick={myFunctionMobilePlus}>
                <i className="fa fa-bars"></i>
            </button>
        </div>

        </div>
        
    <div className='navRight'>
      {/* 
    <Link to="/" className='link btnMore'> 
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"  viewBox="0 0 16 16">
          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
          <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
        </svg>
      </Link>
*/}
    <Link to="/" className='link btnMore'> 

    <div className='cont'>

    <button onClick={toggleCart} className='btnMore cont' > 
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"  viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5Zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0ZM14 14V5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1ZM8 7.993c1.664-1.711 5.825 1.283 0 5.132-5.825-3.85-1.664-6.843 0-5.132Z"/>
    </svg>
    <span className='spanQuantityCart'> <TotalArticlesNav /> </span> 
    </button>
    </div>

    </Link>
    </div>


      </nav>
      
      </div>




      <Routes>
        <Route path="/" element ={ <Home /> } />
        <Route path="/about" element={ <About /> } />
        <Route path="/contact" element={ <Contact /> } />
      </Routes>
      <div className='footer'>
    <div className='footerhead'>

      <div className='footerlinks'>
        <Link to="/" className='footerlink'> shop </Link>
        <Link to="/about" className='footerlink'> about </Link>
        <Link to="/contact" className='footerlink'> contact </Link>
      </div>

      <div className='footerlinks'>

        <a href="https://www.instagram.com/elena.grondin/?hl=fr" target="_blank" className='footerlink' rel="noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
          </svg>
        </a>

        <a href="https://wa.me/33781205401" target="_blank" className='footerlink' rel="noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
              <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
          </svg>
        </a>
      </div>
      <div className='footerlinks'>
        <button  className='footerlink'> INSCRIVEZ-VOUS ET ÉCONOMISEZ </button>
        <button  className='footerlink'> Abonnez-vous pour obtenir des offres spéciales, des cadeaux gratuits et des offres uniques.</button>
        <MailContactForm />
      </div>
      


    </div >
      <p className="footfoot App" >© 2022 Tealea INC </p>
      <a className="footfoot App" href="https://lunacode.fr/" target="_blank" rel="noreferrer">Designed and Developed by Elena GRONDIN</a>
      </div>


    </Router>
  );
}

export default App;
